<!--
* @description:
* @fileName previewModel.vue
* @author hvv
* @date 2022/08/24 13:32:04
!-->
<template>
  <div class="previewDialog">
    <el-card class="box-card">
      <div>我是 问卷名称</div>
      <div>我是 问卷描述</div>
    </el-card>

    <el-form :model="test" label-width="120px" :label-position="'top'">
      <template v-for="(item, index) in form" :key="index">
        <el-card class="box-card">
          <template v-if="item.type == 'input'">
            <el-form-item :label="item.label" :required="item.required">
              <el-input v-model="test.name" />
            </el-form-item>
          </template>

          <template v-if="item.type == 'radio'">
            <el-form-item :label="item.label" :required="item.required">
              <el-radio-group v-model="item.value" class="ml-4">
                <el-radio
                  :label="sItem.label"
                  :key="sIndex"
                  v-for="(sItem, sIndex) in item.child"
                ></el-radio>
              </el-radio-group>
            </el-form-item>
          </template>
        </el-card>
      </template>
    </el-form>
  </div>
</template>

<script>
  import {
    defineComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
  } from 'vue'
  export default defineComponent({
    name: 'previewModel.vue',
    props: {
      form: {
        type: Array,
        default: [],
      },
    },
    setup(props, { emit }) {
      const state = reactive({
        test: {},
      })

      return {
        ...toRefs(state),
      }
    },
  })
</script>
<style lang="scss" scoped>
  .previewDialog {
    width: 50vw;
    padding-top: 20px;
    margin: 0 auto;
    .box-card {
      border-radius: 20px;
    }
  }
</style>
